import config from "./config";
import account from "./account";
import shortopinions from "./shortopinions";
// import secondopinions from "./secondopinions";
import goaes from "./goaes";

export default [
  ...shortopinions,
  // ...secondopinions,
  ...goaes,
  {
    path: "/overview",
    name: "Fallübersicht",
    meta: {
      authorize: true,
      lists: [
        {
          name: "shortOpinionOverviewList",
          entityName: "shortOpinions"
        },{
          name: "goaesOverviewList",
          entityName: "goaes"
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName: "config" */ "@/aquana/views/overview/OverviewContainer.vue"),
  },
  {
    path: "/config",
    name: "Config",
    meta: {
      authorize: true,
      redirect: true
    },
    children: config,
    component: () =>
      import(/* webpackChunkName: "config" */ "@/aquana/views/config/Config.vue"),
  },
  {
    path: "/account",
    name: "account",
    meta: {
      authorize: true,
      redirect: true
    },
    children: account,
    component: () =>
      import(/* webpackChunkName: "account" */ "@/aquana/views/account/Account.vue"),
  },
  {
    path: "/opiniontypes/:name",
    name: "Gutachten",
    meta: {
      authorize: true,
      roles: ["ADMIN"],
    },
    component: () =>
      import(
        /* webpackChunkName: "opiniontypes" */ "@/aquana/views/opiniontypes/OpinionTypeForm.vue"
      ),
  },
  {
    path: "/audittrails",
    name: "Änderungen",
    meta: {
      authorize: true,
      roles: ["ADMIN"],
    },
    component: () =>
      import(
        /* webpackChunkName: "audittrails" */ "@/aquana/views/audittrails/AuditTrailsContainer.vue"
      ),
  },
  {
    path: "/pinboard",
    name: "Pinnwand",
    meta: {
      authorize: true,
      roles: ["MEDEXO"]
    },
    component: () =>
      import(
        /* webpackChunkName: "pinboards" */ "@/aquana/views/pinboards/PinboardContainer.vue"
      )
  },
  {
    path: "/tickets",
    name: "Postfach",
    meta: {
      authorize: true,
      roles: ["MEDEXO"],
    },
    component: () =>
      import(
        /* webpackChunkName: "tickets" */ "@/aquana/views/tickets/TicketContainer.vue"
      ),
  },
  {
    path: "/tickets/:id",
    name: "Postfach",
    meta: {
      authorize: true,
      roles: ["MEDEXO"],
    },
    component: () =>
      import(
        /* webpackChunkName: "tickets" */ "@/aquana/views/tickets/TicketContainer.vue"
      ),
  },
  {
    path: "/vacation",
    name: "Abwesenheitskalender",
    meta: {
      authorize: true,
      roles: ["MEDEXO"],
    },
    component: () =>
      import(
        /* webpackChunkName: "vacation" */ "@/aquana/views/vacation/VacationContainer.vue"
      ),
  },
  {
    path: "/invoice",
    name: "Rechnungen",
    meta: {
      authorize: true,
      roles: ["ADMIN"],
    },
    component: () =>
      import(
        /* webpackChunkName: "invoice" */ "@/aquana/views/invoice/InvoiceContainer.vue"
      ),
  },
  {
    path: "/evaluation",
    name: "Auswertung",
    meta: {
      authorize: true,
      roles: ["ADMIN"],
    },
    component: () =>
      import(
        /* webpackChunkName: "evaluation" */ "@/aquana/views/evaluation/EvaluationContainer.vue"
      ),
  },
  {
    path: "/dashboard",
    name: "Dashbaord",
    meta: {
      authorize: true,
      roles: ["ADMIN"],
    },
    component: () =>
      import(
        /* webpackChunkName: "evaluation" */ "@/aquana/views/dashboard/DashboardContainer.vue"
      ),
  },
  {
    path: "/instructions",
    name: "Anleitungen",
    meta: {
      authorize: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "instructions" */ "@/aquana/views/instructions/InstructionsContainer.vue"
      ),
  },
];
