import Vue from "vue";

export default {
  setOpenBy(state, payload) {
    Vue.set(state.openBy, payload.route, payload.openBy)
  },
  setCount(state, payload) {
    Vue.set(state.count, payload.list, payload.count)
  },
  setUserDashboard(state, payload) {
    Vue.set(state, "userDashboard", payload);
  },
  setAnonStatus(state, payload) {
    Vue.set(state, "anonStatus", payload)
  },
  setAnalyzeProgress(state, payload) {
    Vue.set(state.analyzeProgress, payload.document, payload.analyzeProgress)
  }
};
