export default [
  {
    path: "goaes",
    name: "Gebührenprüfungen",
    meta: {
      authorize: true,
      entityName: "goaes",
      lists: [
        {
          name: "goaesList",
          entityName: "goaes"
        }
      ]
    },
    component: () =>
      import(
        /* webpackChunkName: "goaes" */ "@/aquana/views/goaes/GoaeContainer.vue"
      ),
  },
  {
    path: "goaes/:id",
    name: "Gebührenprüfung",
    meta: {
      authorize: true,
      entityName: "goaes",
    },
    component: () =>
      import(
        /* webpackChunkName: "goaes" */ "@/aquana/views/goaes/GoaeForm.vue"
      ),
  },
];
